<template>
  <div
    class="sms-responser"
    :class="{ 'sms-responser_active': active }"
  >
    <generate-prompt-inbox
      v-if="isAiPromptShown"
      :message="message"
      :profile-id="profile._id"
      :credentials-id="selectedCredential._id"
      request-id-suffix="#sms-responser"
      class="pl-2 pr-2 mb-1"
      message-type="sms"
      @update:generate="message = $event"
    />

    <div class="sms-responser__settings pl-2 mb-1">
      <p class="text_s color_tertiary">
        {{ $t('inboxPage.chatSection.email.from') }}
      </p>
      <select-number
        :selected="sourceNumber"
        :items="numbersList"
        max-height="140"
        @select="sourceNumber = $event"
      />
    </div>

    <div class="sms-responser__settings pl-2 mb-1">
      <p class="text_s color_tertiary">
        {{ $t('inboxPage.chatSection.email.to') }}
      </p>
      <select-number
        :selected="destinationNumber"
        :items="profileNumbersList"
        max-height="110"
        @select="destinationNumber = $event"
      />
    </div>

    <div class="flex-column">
      <span
        class="text_xs color_tertiary margin-left pr-2"
        :class="{'color_danger': currentMessageLength > maxMessageLength}"
      >
        {{ `${currentMessageLength}/${maxMessageLength}` }}
      </span>
      <default-contenteditable
        ref="messageArea"
        v-model="message"
        tag="div"
        :no-n-l="false"
        :no-h-t-m-l="true"
        class="sms-responser__textarea flex-align-self-start"
        :disabled="false"
        :data-placeholder="textareaPlaceholder"
        rows="1"
        @focus.native="active = true"
        @blur.native="active = false"
        @keydown.native.enter.exact.prevent
        @keydown.native.enter.exact="sendSmsHandler"
        @keyup.native="handleInput()"
        @click.native="handleInput()"
      />
      <div class="flex-row flex-justify-content-between pr-2 pl-2">
        <buttons-responser
          ref="responserMenuButtons"
          :has-responses-button="false"
          @open-emojies="showEmojies = !showEmojies"
          @open-generate="isAiPromptShown = !isAiPromptShown"
        />
        <default-button
          :title="$t('actions.send')"
          color="primary"
          form="rectangle"
          size="m"
          :is-disabled="!isSendButtonActive"
          @action="sendSmsHandler"
        />
      </div>
    </div>

    <Picker
      v-show="showEmojies"
      ref="emojies"
      class="sms-responser__emojies"
      :data="emojiesData"
      :emoji-size="24"
      :native="true"
      set="native"
      :per-line="8"
      :show-preview="false"
      :show-skin-tones="false"
      :i18n="emojiPickerLocales"
      @select="insertEmoji"
    />
  </div>
</template>
<script setup>
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import DefaultContenteditable from '@/components/base/uiKit/DefaultConteneditable.vue';
  import SelectNumber from '@/components/inboxPage/chat/sms/responser/SelectNumber.vue';
  import ButtonsResponser from '@/components/inboxPage/chat/messages/responser/ButtonsResponser.vue';
  import GeneratePromptInbox from '@/components/base/GeneratePrompt/GeneratePromptInbox.vue';

  import { computed, onMounted, ref } from 'vue';
  import { useI18n } from '@/i18n';
  import { sendSms } from '@/api/profilesMethods';
  import { useContenteditable } from '@/use/useContenteditable';
  import { Picker, EmojiIndex } from 'emoji-mart-vue-fast';
  import data from 'emoji-mart-vue-fast/data/all.json';
  import 'emoji-mart-vue-fast/css/emoji-mart.css';
  import { generateEmojiPickerLocale } from '@/utils/translation/utils';
  import { ClosableData, useClosable } from '@/use/useClosable';
  import { useStore } from '@/store';
  import { notifyError } from '@/use/notifyError';
  import { formatPhoneNumber } from '@/utils/dialer/utils';

  const { t } = useI18n();
  const store = useStore();
  const contenteditable = useContenteditable();
  const emit = defineEmits(['send']);
  const props = defineProps({
    profile: {
      type: Object,
      default: null,
    },
  });
  const message = ref('');
  const isAiPromptShown = ref(false);
  const messageArea = ref(null);
  const textareaPlaceholder = t('placeholders.message');
  const destinationNumber = ref(null);
  const sourceNumber = ref(null);
  const active = ref(false);
  const showSaved = ref(false);
  const showEmojies = ref(false);
  const emojiesData = ref(new EmojiIndex(data));
  const emojies = ref(null);
  const responserMenuButtons = ref(null);
  const maxMessageLength = 670;

  onMounted(() => {
    const excludedEmojiesToggler = ref(responserMenuButtons.value.$refs.emojiesPickerToggler);

    useClosable(new ClosableData(
      emojies,
      excludedEmojiesToggler,
      showEmojies
    ));
  });

  const currentMessageLength = computed(() => message.value.length);

  const emojiPickerLocales = computed(() => {
    return generateEmojiPickerLocale();
  });
  const selectedCredential = computed(() => store.getters['credentials/selectedCredential']);
  const numbersList = computed(() => store.getters['vox/numbersList']
    ?.filter(e => e.is_sms_enabled && e.status === 'active')?.map(phone => {
      phone.rightData = phone?.state
        ? `${phone.country.country_code} • ${phone.state.name} • ${phone.region.name}`
        : `${phone.country.country_code} • ${phone.region.name}`;
      phone.name = formatPhoneNumber(phone.number);
      return phone;
    }));

  const enrichedPhones = computed(() => props.profile.enriched_data.enriched_phones.map(e => {
    if (e.phone) {
      const number = e.phone.startsWith('+') ? e.phone.substring(1) : e.phone;
      e.name = formatPhoneNumber(number);
      e.rightData = 'Enriched';
    }
    return e;
  }));
  const profileNumbersList = computed(() => {
    return props.profile.phone ? [...enrichedPhones.value, { name: formatPhoneNumber(props.profile.phone), rightData: 'Direct' }] : [...enrichedPhones.value];
  });

  const isSendButtonActive = computed(() => {
    return Boolean(message.value.trim()) &&
      Boolean(destinationNumber.value) &&
      Boolean(sourceNumber.value) &&
      currentMessageLength.value <= maxMessageLength;
  });

  const sendSmsHandler = notifyError(async () => {
    const newMessage = await sendSms(
      props.profile._id,
      sourceNumber.value.name.match(/[0-9]+/g)?.join(''),
      message.value,
      destinationNumber.value.name.match(/[0-9]+/g)?.join(''),
      selectedCredential.value._id
    );
    emit('send', newMessage);
    message.value = '';
  });
  function handleInput () {
    if (message.value.length <= 1 && message.value[0] === '/') {
      showSaved.value = true;
    }
    contenteditable.saveRange();
  }

  function insertEmoji (emoji) {
    contenteditable.addTemplate(emoji.native, messageArea);
  }
  onMounted(() => {
    contenteditable.saveRange();
    destinationNumber.value = profileNumbersList.value[0] || null;
    sourceNumber.value = numbersList.value[0] || null;
  });

</script>
<style lang="scss">
.sms-responser {
  position: relative;
  padding: 16px 0;
  border-radius: 8px;
  background: var(--background-color);

  &_active {
    outline: 1px solid var(--primary-cl);
  }

  &__settings {
    display: grid;
    grid-template-columns: 65px auto;
    gap: 8px;
    align-items: center;
    justify-content: start;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--color-other-divider);
  }

  &__textarea {
    flex-grow: 1;
    @include text_s;
    border: 0;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 200px;
    padding: 0 16px 16px;

    &::-webkit-scrollbar {
      display: none;
    }
  };

  &__emojies {
    position: absolute;
    bottom: calc(100% + 16px);
    right: 0;
  }
}
</style>
