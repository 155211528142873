<template>
  <div
    :class="[
      'responser',
      { 'responser_active': active,
        'responser_gray': $route.name === 'my-prospects'
      },
    ]"
  >
    <generate-prompt-inbox
      v-if="showInputFeature"
      :message="message"
      :profile-id="profile._id"
      :credentials-id="selectedCredential._id"
      request-id-suffix="#messages-responser"
      class="mb-2"
      message-type="linkedin"
      @update:generate="message = $event"
    />

    <div class="responser__content">
      <default-contenteditable
        ref="messageArea"
        v-model="message"
        tag="div"
        :no-n-l="false"
        :no-h-t-m-l="true"
        class="responser__textarea"
        :contenteditable="!isDisabled"
        :data-placeholder="textareaPlaceholder"
        rows="1"
        @focus.native="active = true"
        @blur.native="active = false"
        @keydown.native.enter.exact.prevent
        @keydown.native.enter.exact="sendMessageHandler"
        @keyup.native="handleInput()"
        @click.native="handleInput()"
      />
    </div>
    <div class="mt-1 responser__controls">
      <div class="flex-row flex-gap-8">
        <buttons-responser
          class="flex-align-self-end"
          :is-edit-mode="isEditMode"
          @open-responses="showBlocks.responsesToggler = !showBlocks.responsesToggler"
          @open-emojies="showBlocks.emojiesPickerToggler = !showBlocks.emojiesPickerToggler"
          @open-generate="showInputFeature = !showInputFeature"
          @save="$emit('update-message', message)"
          @exit-edit-mode="$emit('exit-edit-mode')"
        />

        <sender-info
          v-if="showSender"
          :name="selectedCredential.full_name"
          :avatar-url="selectedCredential.photo_link"
        />
      </div>

      <div class="responser__control">
        <default-button
          :title="isEditMode ? $t('actions.save') : $t('actions.send')"
          color="primary"
          form="rectangle"
          size="m"
          :is-disabled="!message.trim()"
          @action="sendMessageHandler"
        />
        <default-button
          v-show="!isEditMode"
          data-excluded="schedule"
          icon-name="bx-calendar"
          color="primary"
          form="square"
          size="m"
          :is-disabled="!message.trim()"
          @action="showBlocks.schedule = !showBlocks.schedule"
        />
        <saved-responses
          v-show="showBlocks.responsesToggler"
          data-closable="responsesToggler"
          class="responser__saved-responses"
          :show-saved="true"
          :profile="profile"
          @insert="insertResponse"
        />
        <Picker
          v-show="showBlocks.emojiesPickerToggler"
          data-closable="emojiesPickerToggler"
          class="responser__emojies"
          :data="emojiesData"
          :emoji-size="24"
          :native="true"
          set="native"
          :per-line="8"
          :show-preview="false"
          :show-skin-tones="false"
          :i18n="emojiPickerLocales"
          @select="insertEmoji"
        />
        <schedule-message
          v-show="showBlocks.schedule"
          data-closable="schedule"
          class="responser__schedule"
          @cancel="showBlocks.schedule = false"
          @send="sendMessageHandler"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import ButtonsResponser from '@/components/inboxPage/chat/messages/responser/ButtonsResponser';
  import SavedResponses from '@/components/inboxPage/chat/messages/responser/savedResponses/SavedResponses';
  import ScheduleMessage from '@/components/inboxPage/chat/messages/responser/ScheduleMessage';
  import GeneratePromptInbox from '@/components/base/GeneratePrompt/GeneratePromptInbox.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import SenderInfo from '@/components/inboxPage/chat/SenderInfo.vue';

  import { closableMixin } from '@/mixins/closableMixin.js';
  import { mapGetters } from 'vuex';
  import { Picker, EmojiIndex } from 'emoji-mart-vue-fast';
  import data from 'emoji-mart-vue-fast/data/all.json';
  import 'emoji-mart-vue-fast/css/emoji-mart.css';
  // 'Hide AI': 'Show AI'
  import { sendMessage } from '@/api/profilesMethods';
  import DefaultContenteditable from '@/components/base/uiKit/DefaultConteneditable.vue';
  import { ContenteditableMixin } from '@/mixins/ContenteditableMixin';
  import { generateEmojiPickerLocale } from '@/utils/translation/utils';

  export default {
    name: 'MessagesResponser',
    components: {
      DefaultContenteditable,
      ButtonsResponser,
      SavedResponses,
      Picker,
      ScheduleMessage,
      GeneratePromptInbox,
      DefaultButton,
      SenderInfo,
    },
    mixins: [closableMixin, ContenteditableMixin],
    props: {
      messageToEdit: {
        type: String,
        default: '',
      },
      profile: {
        type: Object,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        required: true,
      },
      isEditMode: {
        type: Boolean,
        default: true,
      },
      showSender: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        active: false,
        message: '',
        emojiesData: new EmojiIndex(data),
        showInputFeature: false,
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      textareaPlaceholder () {
        return this.isDisabled
          ? this.profile.inmail_status === 'pending'
            ? 'This user has not accepted your inMail yet'
            : 'This user has declined your inMail'
          : this.$t('placeholders.message');
      },
      emojiPickerLocales () {
        return generateEmojiPickerLocale();
      },
    },
    watch: {
      'profile._id' () {
        this.message = '';
      },
      messageToEdit (newMessageToEdit) {
        if (newMessageToEdit) {
          this.message = newMessageToEdit;
          this.$refs.messageArea.$el.focus();
        } else {
          this.message = newMessageToEdit;
          this.$refs.messageArea.$el.blur();
        }
      },
    },
    methods: {
      handleInput () {
        if (this.message.length <= 1 && this.message[0] === '/') {
          this.showSaved = true;
        }
        this.saveRange();
      },
      insertResponse (response) {
        this.message = response;
        this.showBlocks.responsesToggler = false;
      },
      insertEmoji (emoji) {
        this.addTemplate(emoji.native, 'messageArea');
      },
      transformDate (date = new Date()) {
        if (!this.selectedCredential.ui_pc_timezone) {
          return new Date(date - new Date().getTimezoneOffset() * 60 * 1000);
        }
        return date.toISOString().match(/[^T]+T[^:]+[^.]+/g)[0] + '.000Z';
      },
      async sendMessageHandler (date = null) {
        if (this.isEditMode) {
          this.$emit('update-message', this.message);
          return;
        }

        if (date) {
          date = this.transformDate(date);
        }

        try {
          const newMessage = await sendMessage(
            this.profile._id,
            this.selectedCredential._id,
            this.message,
            date
          );
          this.$emit('send', newMessage);
          this.message = '';
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
    },

  };
</script>
<style lang="scss">
.responser {
  position: relative;
  z-index: 1;
  display: grid;
  padding: 16px;
  border-radius: 8px;
  background: var(--background-color);

  &__content {
    @include flex-row;
    word-break: break-word;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
  }

  &__control {
    display: flex;
    gap: 4px;
    justify-content: space-between;
  }

  &__textarea {
    flex-grow: 1;
    @include text_s;
    border: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 200px;
    &::-webkit-scrollbar {
      display: none;
    }
  };

  &__saved-responses {
    position: absolute;
    bottom: calc(100% + 16px);
    left: 0;
  }

  &__emojies {
    position: absolute;
    bottom: calc(100% + 16px);
    right: 0;
  }

  &__schedule {
    position: absolute;
    bottom: calc(100% + 16px);
    right: 0;
  }
}
.emoji-mart {
  background-color: var(--message-test);
}

.emoji-mart-search input {
  background-color: var(--secondary-btn-bg);
  border: 1px solid var(--border-cl);
  color: var(--text-color);
}

.emoji-mart-category-label span {
  background-color: var(--message-test);
  color: var(--text-color);
}
</style>
