<!--© Copyright, Dexima Inc.2023 — All rights reserved.-->
<template>
  <portal to="body-end-2">
    <modal
      :key="key"
      width="400px"
      height="auto"
      :name="name"
      @closed="handleClose"
    >
      <section class="modal push-crm-owner">
        <default-button
          class="push-crm-owner__close-button"
          color="neutral"
          form="circle"
          size="m"
          icon-name="bx-x"
          @action="handleClose"
        />

        <h2 class="push-crm-owner__title">
          Push Settings
        </h2>

        <main class="push-crm-owner__main flex-column mt-2 flex-gap-16">
          <default-select
            :value="chosenOwner?.name || ''"
            :items="owners"
            max-height="300"
            data-closable="pushOwner"
            placeholder="Choose owner..."
            :is-fulled="true"
            :read-only="true"
            :is-disabled="!owners.length"
            @select="chosenOwner = $event"
          />

          <section
            v-if="crm === 'hubspot' && Boolean(owners.length)"
            class="flex-row flex-justify-content-between"
          >
            <label class="text_s">
              Enable profiles deduplication
            </label>

            <default-switcher
              size="s"
              :is-checked="isDeduplicationEnabled"
              :is-disabled="!owners.length"
              @change="isDeduplicationEnabled = !isDeduplicationEnabled"
            />
          </section>

          <section v-if="Boolean(owners.length)">
            <p class="text_s">
              Select emails order
            </p>

            <p class="text_xs color_tertiary mt-05 flex-row flex-gap-4">
              <i class="bx bx-info-circle icon-size-12" />
              <span>
                You can move items around
              </span>
            </p>

            <draggable
              v-model="profileEmailsOrder"
              :animation="200"
              tag="div"
              class="push-crm-owner__emails-list-wrapper mt-1 p-05"
              :class="{ 'push-crm-owner__emails-list-wrapper_dragging': isDragging }"
              @start="isDragging = true"
              @end="isDragging = false"
            >
              <transition-group
                name="emails-list"
                tag="ul"
                class="push-crm-owner__emails-list flex-row flex-gap-8"
              >
                <li
                  v-for="email in profileEmailsOrder"
                  :key="email.id"
                  class="push-crm-owner__emails-list-item text_s px-1 py-05"
                >
                  {{ email.name }}
                </li>
              </transition-group>
            </draggable>
          </section>
        </main>

        <menu class="flex-row flex-gap-8 margin-left mt-2">
          <default-button
            size="m"
            title="Cancel"
            color="neutral"
            form="rectangle"
            @action="handleClose"
          />
          <default-button
            :is-disabled="!chosenOwner"
            size="m"
            title="Push"
            color="primary"
            form="rectangle"
            @action="pushToCrmHandler"
          />
        </menu>
      </section>
    </modal>
  </portal>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import DefaultSelect from '@/components/base/uiKit/DefaultSelect.vue';
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher.vue';
  import draggable from 'vuedraggable';
  import { useModal } from '@/use/useModal';
  import { useSnack } from '@/lib/useSnack';
  import { getUsers } from '@/api/crmIntegration';

  const props = defineProps({
    show: {
      type: Boolean,
      default: false,
    },
    crm: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'PushToCrmOwnerModal',
    },
  });

  const emit = defineEmits(['close', 'push-to-crm']);

  // #region Composables
  const $modal = useModal();
  const { $snack } = useSnack();
  // #endregion

  // #region Deduplication switcher
  const isDeduplicationEnabled = ref(false);
  // #endregion

  // #region Get owners
  const owners = ref([]);

  const chosenOwner = ref(null);

  watch(() => props.show, async (newValue) => {
    if (newValue && !owners.value.length) {
      owners.value = await getOwners(props.crm) || [];
    }
  });

  const getOwners = async (platform) => {
    try {
      return await getUsers(platform);
    } catch ({ message }) {
      $snack.error(message);
    }
  };
  // #endregion

  // #region Show handler
  const key = ref(props.name);

  watch(() => props.show, async (newValue) => {
    if (newValue) {
      $modal.show(props.name);
    } else if (!newValue) {
      $modal.hide(props.name);
      key.value = `${key.value} ${new Date().getTime()}`;
      chosenOwner.value = null;
    }
  });

  const handleClose = () => {
    emit('update:show', false);
  };
  // #endregion

  // #region Emails order
  const isDragging = ref(false);

  const profileEmailsOrder = ref([
    {
      id: 'personal',
      name: 'Personal',
    },
    {
      id: 'predicted',
      name: 'Enriched',
    },
    {
      id: 'work',
      name: 'Work',
    },
  ]);
  // #endregion

  // #region Push to CRM
  const pushToCrmHandler = () => {
    emit('push-to-crm', {
      owner: chosenOwner.value,
      isDeduplicate: isDeduplicationEnabled.value,
      emails: profileEmailsOrder.value.map((email) => email.id),
    });
  };
  // #endregion
</script>

<style lang="scss">
.push-crm-owner {
  transition: 0.8s;
  height: 100%;
  position: relative;
  background-color: var(--background-color);
  padding: 24px;
  border-radius: 8px;

  &__close-button {
    position: absolute;
    top: 0;
    left: calc(100% + 8px);
  }

  &__main {
    width: 100%;
  }

  &__emails-list {
    &-wrapper {
      width: max-content;
      background-color: var(--neutral-50);
      border: 1px dashed transparent;
      border-radius: 4px;
      transition: background-color .2s ease-out, border-color .2s ease-out;

      &_dragging {
        border-color: var(--neutral-300);
      }
    }

    &-item {
      border: 1px solid var(--neutral-200 );
      border-radius: 4px;
      background-color: white;
      cursor: grab;

      &.sortable-ghost {
        opacity: .5;
      }

      &.sortable-chosen:not(.sortable-ghost) {
        border-color: var(--primary-cl);
      }
    }
  }
}
</style>
