import { render, staticRenderFns } from "./SmsResponser.vue?vue&type=template&id=49cd70f6"
import script from "./SmsResponser.vue?vue&type=script&setup=true&lang=js"
export * from "./SmsResponser.vue?vue&type=script&setup=true&lang=js"
import style0 from "./SmsResponser.vue?vue&type=style&index=0&id=49cd70f6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../temp_divo-ui/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports